import { HeaderFull } from './HeaderFull/HeaderFull';
import { HeaderMinimal } from './HeaderMinimal/HeaderMinimal';

interface HeaderProps {
  headerStyle?: string;
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { headerStyle } = props;

  if (headerStyle === 'minimal') {
    return <HeaderMinimal />;
  }

  return <HeaderFull />;
};
