import { STREET, ZIP, CITY, PHONE } from 'lib/constants/address';
import { LINKED_DATA_SCHEMA } from 'lib/constants/jsonld';

export const getJsonLDsForSchema = (schema: string) => {
  switch (schema) {
    case LINKED_DATA_SCHEMA.article:
      return {
        organization: true,
        website: true,
        breadcrumb: true,
        article: true,
        linkedData: true,
      };
    case LINKED_DATA_SCHEMA.website:
      return {
        organization: true,
        website: true,
        breadcrumb: false,
        article: false,
        linkedData: true,
      };
    default:
      return {
        organization: false,
        website: false,
        breadcrumb: false,
        article: false,
        linkedData: false,
      };
  }
};

// These helpers are for the JSON-LD scripts in the Head component
// They are structured meta data for search engines which helps our SEO
// Tester: https://developers.google.com/search/docs/appearance/structured-data

// Used for AuthorBox
export const getPersonJsonLD = (
  personName: string,
  imageUrl: string,
  imageTitle: string,
  socialMediaUrls: string[],
) => {
  const personNameForId = personName.trim().toLowerCase();
  const imageTitleForId = imageTitle.trim().toLowerCase();

  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "Person",
    "@id": "https://www.finanzcheck.de/#/person/${personNameForId}",
    "name": "${personName}",
    "image": {
      "@type": "ImageObject",
      "@id": "https://www.finanzcheck.de/#/image/${imageTitleForId}",
      "url": "https:${imageUrl}",
      "caption": "${personName}"
    },
    "sameAs": [
      ${socialMediaUrls.map((url) => `"${url}"`).join(',')}
    ]
  }`,
  };
};

export const getOrganizationJsonLD = () => {
  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://www.finanzcheck.de/#organization",
    "name": "Finanzcheck",
    "legalName": "FFG Finanzcheck Finanzportale GmbH",
    "url": "https://www.finanzcheck.de",
    "description": "FINANZCHECK - Das TÜV geprüfte Onlineportal für Ihren günstigen Kredit. Starten Sie noch heute Ihren Vergleich und finden Sie mit uns für sich das optimale Angebot.",
    "telephone": "${PHONE}",
    "email": "kredite@finanzcheck.de",
    "sameAs": [
        "https://de.wikipedia.org/wiki/Finanzcheck.de",
        "https://www.wikidata.org/wiki/Q54408082",
        "https://www.facebook.com/finanzcheck.de",
        "https://twitter.com/finanzcheck_de",
        "https://www.youtube.com/channel/UCXufMPgryYGPINluehn_91Q",
        "https://www.xing.com/companies/ffgfinanzcheckfinanzportalegmbh",
        "https://www.linkedin.com/company/finanzcheck-de/"
    ],
    "address": {
        "@type": "PostalAddress",
        "@id": "https://www.finanzcheck.de/#address",
        "postalCode": "${ZIP}",
        "addressLocality": "${CITY}",
        "addressRegion": "${CITY}",
        "streetAddress": "${STREET}",
        "addressCountry": "DE"
    },
    "logo": {
        "@type": "ImageObject",
        "@id": "https://www.finanzcheck.de/#logo",
        "url": "https://www.finanzcheck.de/static/images/json-ld/finanzcheck-logo.png",
        "width": 160,
        "height": 160,
        "caption": "Finanzcheck"
    }
  }`,
  };
};

export const getWebsiteJsonLD = () => {
  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://www.finanzcheck.de/#website",
    "url": "https://www.finanzcheck.de",
    "name": "Finanzcheck",
    "publisher": {
        "@id": "https://www.finanzcheck.de/#organization"
    }
  }`,
  };
};

export const getBreadcrumbJsonLD = (url: string) => {
  const items = url.split('/').filter((part) => part !== '');

  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": "https://www.finanzcheck.de${url}#breadcrumb",
    "itemListElement": [
  ${items
    .map((item, index) => {
      const url = items.slice(0, index + 1).join('/');
      const name = item.replace(/-/g, ' ');

      return `     {
        "@type": "ListItem",
        "position": ${index + 1},
        "item": {
          "@type": "WebPage",
          "@id": "https://www.finanzcheck.de/${url}/",
          "url": "https://www.finanzcheck.de/${url}/",
          "name": "${name.toUpperCase()}"
        }
      }`;
    })
    .join(',')}
    ]
  }`,
  };
};

export const getArticleJsonLD = (
  url: string,
  articleSection: string,
  headline: string,
  authorName: string,
  datePublished: string,
  dateModified: string,
) => {
  const capitalizedArticleSection =
    articleSection.charAt(0).toUpperCase() + articleSection.slice(1);
  return {
    __html: `{
    "@type": "Article",
    "@id": "https://www.finanzcheck.de${url}#article",
    "isPartOf": {
        "@id": "https://www.finanzcheck.de${url}#webpage"
    },
    "datePublished": "${datePublished}",
    "dateModified": "${dateModified}",
    "mainEntityOfPage": {
        "@id": "https://www.finanzcheck.de${url}#webpage"
    },
    "publisher": {
        "@id": "https://www.finanzcheck.de/#organization"
    },
    "image": {
        "@id": "https://www.finanzcheck.de${url}#primaryimage"
    },
    "articleSection": "${capitalizedArticleSection}",
    "headline": "${headline}",
    "author": {
        "@id": "https://www.finanzcheck.de/#/person/${authorName.toLocaleLowerCase()}"
    }
  }`,
  };
};

export const getCustomPageJsonLD = (customJSON: any) => {
  try {
    const parsedJson = JSON.stringify(customJSON);
    return {
      __html: parsedJson,
    };
  } catch (error) {
    return {
      __html: `{
      "error": "Could not parse custom json: ${error}"
    }`,
    };
  }
};
