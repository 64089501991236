import { Container, Typography, Link } from '@mui/material';
import NextLink from 'next/link';

export const SeaLegalText = ({ showLegalText }: { showLegalText: boolean }) => {
  if (!showLegalText) return null;

  return (
    <Container maxWidth="lg" sx={{ marginTop: '16px' }}>
      <Typography variant="body2" color="textSecondary">
        <b>Sparen Sie über 35% mit Finanzcheck:</b> Vgl. der eff.
        Jahreszinssätze neu vergebener Konsumentenkredite (lt. dt. Bundesbank)
        mit den eff. Jahreszinssätzen von über Finanzcheck neu vermittelten
        Krediten 2020.{' '}
        <Link
          href="/kreditvergleich/"
          component={NextLink}
          color="textSecondary"
          prefetch={false}
          sx={{ textDecoration: 'underline' }}
        >
          Mehr&nbsp;Info
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Laufzeit: Minimum 6 Monate, Maximum 120 Monate | Zinssätze: Minimum
        -0,40 % effektiver Jahreszins, Maximum 19,90 % effektiver Jahreszins
        Solaris SE, CuvryStraße 53, 10997 Berlin.
      </Typography>
    </Container>
  );
};
