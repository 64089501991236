import { Entry, createClient } from 'contentful';
import { urlToSlug } from './slugHelper';
import { stripSysPage } from 'lib/contentful/stripSys';

import {
  IFaqEntry,
  IPgPageBuilder,
  IPgPageBuilderFields,
  IPressRelease,
} from 'types/generated/contentful';
import { IPageConfig } from 'lib/pageConfig/pageConfigHelper';

const CONTENTFUL_PREVIEW_HOST = 'preview.contentful.com';
const CONTENTFUL_DELIVERY_HOST = 'cdn.contentful.com';

export const client = ({ preview } = { preview: false }) => {
  const accessToken = preview
    ? process.env.CONTENTFUL_PREVIEW_TOKEN!
    : process.env.CONTENTFUL_DELIVERY_TOKEN!;
  const host = preview ? CONTENTFUL_PREVIEW_HOST : CONTENTFUL_DELIVERY_HOST;
  return createClient({
    host,
    space: process.env.CONTENTFUL_SPACE_ID!,
    accessToken,
  });
};

// As this is client side rendered, the token must be supplied
// Otherwise it will be in the client side code.
export const legalDocsPreviewClient = (token: string) => {
  return createClient({
    host: CONTENTFUL_PREVIEW_HOST,
    space: process.env.CONTENTFUL_LEGAL_SPACE_ID!,
    accessToken: token,
  });
};

// urlPart must start and end with /
export const getSubPaths = async (urlPart: string) => {
  const { items: pages } = await client().getEntries({
    content_type: 'pgPageBuilder',
    'fields.url[match]': urlPart,
    include: 0,
    limit: 1000, // WARNING: If we get over 1000 subpages, this will break
  });

  const subUrls: string[] = [];

  pages.forEach((item: Entry) => {
    const page = item as IPgPageBuilder;
    const { url } = page.fields as IPgPageBuilderFields;

    const subUrl = url.replace(urlPart, '');

    subUrls.push(subUrl);
  });

  const paths = subUrls.map((subUrl) => ({
    params: { slug: urlToSlug(subUrl) },
  }));

  return [...paths];
};

export const getAllPaths = async () => {
  const { items: pages } = await client().getEntries({
    content_type: 'pgPageBuilder',
    include: 0,
    limit: 1000, // WARNING: If we get over 1000 subpages, this will break
  });

  const urls: string[] = [];

  pages.forEach((item: Entry) => {
    const page = item as IPgPageBuilder;
    const { url } = page.fields as IPgPageBuilderFields;
    urls.push(url);
  });

  const paths = urls.map((url) => ({
    params: { slug: urlToSlug(url) },
  }));

  return [...paths];
};

interface IGetPageOptions {
  preview?: boolean;
  applyStripSys?: boolean;
}

export const getPbPageByUrl = async (
  url: string,
  options?: IGetPageOptions,
) => {
  const { preview = false, applyStripSys = true } = options || {};

  // Include 10 is the max. We want the whole page data here
  const res = await client({ preview }).getEntries({
    content_type: 'pgPageBuilder',
    'fields.url': url,
    include: 10,
  });

  const page = res.items[0];

  if (applyStripSys) {
    const strippedPage = stripSysPage(page);
    return strippedPage;
  }

  return page as IPgPageBuilder;
};

export interface ISitemapEntry {
  loc: string;
  lastmod: string;
  isIndexed?: boolean;
}
export type SiteMapEntries = ISitemapEntry[];

export const getAllPbPagesForSitemap = async (
  includeNonIndexed: boolean = false,
) => {
  const { items: pages } = await client().getEntries({
    content_type: 'pgPageBuilder',
    order: ['fields.url'],
    include: 0,
    limit: 1000, // WARNING: If we get over 1000 subpages, this will break
  });

  const sitemapEntries = pages
    .filter((item) => {
      const pbPage = item as IPgPageBuilder;
      const pageFields = pbPage.fields as IPgPageBuilderFields;
      const { pageConfig } = pageFields;
      const { includeSEO = false } = pageConfig as IPageConfig;
      if (includeNonIndexed) return true; // Skip filter
      // Filter out pages that are not to be indexed
      return includeSEO === true;
    })
    .map((item) => {
      const pbPage = item as IPgPageBuilder;
      const pageFields = pbPage.fields as IPgPageBuilderFields;

      // We no longer use priotity and changefreq as it is no longer used by google
      const { url, pageConfig } = pageFields;
      const { includeSEO = false } = pageConfig as IPageConfig;
      const {
        sys: { updatedAt },
      } = pbPage;

      return {
        loc: url,
        lastmod: new Date(updatedAt).toISOString(),
        isIndexed: includeSEO,
      };
    });

  return sitemapEntries;
};

export const getFaqEntries = async () => {
  const { items } = await client().getEntries({
    content_type: 'faqEntry',
    order: ['fields.sortingWeight'],
  });

  return items as IFaqEntry[];
};

export const getPressReleases = async () => {
  const { items } = await client().getEntries({
    content_type: 'pressRelease',
    order: ['-fields.date'],
  });

  return items as IPressRelease[];
};
