/**
 * Converts a slug which is either a string or a array of srings to a url
 * plus a prefix which must start with a slash and end with a slash.
 */
export const slugToUrl = (prefix: string, slug: string | string[]) => {
  // if we are om the index page, there will be no slug, we can then immediately return
  if (!slug) {
    return prefix;
  }

  const isString = typeof slug === 'string';

  if (prefix[0] !== '/' || prefix[prefix.length - 1] !== '/') {
    throw new Error('Slug prefix must start and end with a slash');
  }

  return isString ? `${prefix}${slug}/` : `${prefix}${slug.join('/')}/`;
};

/**
 * Converts a url to a slug. This is the reverse of slugToUrl.
 */
export const urlToSlug = (url: string) => {
  const urlParts = url.split('/').filter((part) => part !== '');

  return urlParts as string[];
};
