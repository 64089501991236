import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';

import type { AuthorBoxProps } from 'components/AuthorBox/AuthorBox';
import type { ButtonProps } from 'components/ContentfulContent/Button/Button';
import type { EmbeddedImageProps } from 'components/ContentfulContent/EmbeddedImage/EmbeddedImage';
import type { EkomiProps } from './Ekomi/Ekomi';
import type { FlexibleRowProps } from 'components/ContentfulContent/FlexibleRow/FlexibleRow';
import type { InfoBoxProps } from 'components/ContentfulContent/InfoBox/InfoBox';
import type { LinkBoxProps } from 'components/ContentfulContent/LinkBox/LinkBox';
import type { ParagraphWithIconProps } from 'components/ContentfulContent/ParagraphWithIcon/ParagraphWithIcon';
import type { PrototypeProps } from 'components/ContentfulContent/Prototypes/Prototype';
import type { RichTextProps } from 'components/ContentfulContent/RichText/RichText';
import type { StageProps } from 'components/ContentfulContent/Stage/Stage';
import type { StageExclusiveBadgesProps } from 'components/ContentfulContent/Stage/StageExclusiveBadges';
import type { TableProps } from 'components/ContentfulContent/Table/Table';
import type { TextAndImageProps } from 'components/ContentfulContent/TextAndImage/TextAndImage';
import type { OneThirdTwoThirdsRowProps } from './OneThirdTwoThirdsRow/OneThirdTwoThirdsRow';
import { isLive } from 'lib/helpers/environmentsHelper';

// Components that we always want to have ready to render
// are declared here and not dynamically imported.
import { UnknownComponent } from './UnkownComponent';

const componentMap = {
  ceAuthorBox: dynamic<AuthorBoxProps>(() =>
    import('components/AuthorBox/AuthorBox').then((module) => module.AuthorBox),
  ),
  ceEmbeddedImage: dynamic<EmbeddedImageProps>(() =>
    import('components/ContentfulContent/EmbeddedImage/EmbeddedImage').then(
      (module) => module.EmbeddedImage,
    ),
  ),
  ceEkomi: dynamic<EkomiProps>(() =>
    import('components/ContentfulContent/Ekomi/Ekomi').then(
      (module) => module.Ekomi,
    ),
  ),
  ceInfoBox: dynamic<InfoBoxProps>(() =>
    import('components/ContentfulContent/InfoBox/InfoBox').then(
      (module) => module.InfoBox,
    ),
  ),
  ceLinkBox: dynamic<LinkBoxProps>(() =>
    import('components/ContentfulContent/LinkBox/LinkBox').then(
      (module) => module.LinkBox,
    ),
  ),
  ceTable: dynamic<TableProps>(() =>
    import('components/ContentfulContent/Table/Table').then(
      (module) => module.Table,
    ),
  ),
  ceParagraphWithIcon: dynamic<ParagraphWithIconProps>(() =>
    import(
      'components/ContentfulContent/ParagraphWithIcon/ParagraphWithIcon'
    ).then((module) => module.ParagraphWithIcon),
  ),
  ceRichText: dynamic<RichTextProps>(() =>
    import('components/ContentfulContent/RichText/RichText').then(
      (module) => module.RichText,
    ),
  ),
  ceStage: dynamic<StageProps>(() =>
    import('components/ContentfulContent/Stage/Stage').then(
      (module) => module.Stage,
    ),
  ),
  ceStageExclusiveBadges: dynamic<StageExclusiveBadgesProps>(() =>
    import('components/ContentfulContent/Stage/StageExclusiveBadges').then(
      (module) => module.StageExclusiveBadges,
    ),
  ),
  ceTextImage: dynamic<TextAndImageProps>(() =>
    import('components/ContentfulContent/TextAndImage/TextAndImage').then(
      (module) => module.TextAndImage,
    ),
  ),
  seFlexibleRow: dynamic<FlexibleRowProps>(() =>
    import('components/ContentfulContent/FlexibleRow/FlexibleRow').then(
      (module) => module.FlexibleRow,
    ),
  ),
  seOnethirdTwothirdsRow: dynamic<OneThirdTwoThirdsRowProps>(() =>
    import(
      'components/ContentfulContent/OneThirdTwoThirdsRow/OneThirdTwoThirdsRow'
    ).then((module) => module.OneThirdTwoThirdsRow),
  ),
  feButton: dynamic<ButtonProps>(() =>
    import('components/ContentfulContent/Button/Button').then(
      (module) => module.Button,
    ),
  ),
  cePrototype: dynamic<PrototypeProps>(() =>
    import('components/ContentfulContent/Prototypes/Prototype').then(
      (module) => module.Prototype,
    ),
  ),
  leContentTraits: () => null, // Deprecated and suppressed component
  feKuza: () => null, // Deprecated and suppressed component
};

export const ContentfulItem = (item: any) => {
  const { query } = useRouter();

  const displayBoundingBox =
    typeof query?.displayBox !== 'undefined' && !isLive();

  const componentName: string = item?.sys?.contentType?.sys.id;
  const isInline = item?.isInline || false;

  if (!componentName) return null;

  // @ts-ignore
  const Component = componentMap[componentName];
  if (!Component) return <UnknownComponent name={componentName} />;

  if (displayBoundingBox && !isInline) {
    return (
      <Box
        sx={{
          border: '1px solid magenta',
        }}
      >
        <Component {...item.fields} isInline={isInline} />
      </Box>
    );
  }

  // @ts-ignore
  return <Component {...item.fields} isInline={isInline} />;
};
