import type {
  IPgPageBuilder,
  IPgPageBuilderFields,
} from 'types/generated/contentful';

import { ContentfulItem } from './ContentfulItem';

interface ContentfulContentProps {
  page: IPgPageBuilder | null;
}

/**
 * Recursively render contentful content from the content field of the page builder page.
 * This first Level just walks through the top level components. The ContentfulItem component
 * then renders the actual component and progresses through its children.
 */
export const ContentfulContent = ({ page }: ContentfulContentProps) => {
  if (!page) {
    return null;
  }

  const { contentWrapper } = page!.fields as IPgPageBuilderFields;
  const contentItems = contentWrapper!.map((item: any, index: number) => (
    <ContentfulItem key={index} {...item} />
  ));

  return <>{contentItems}</>;
};
